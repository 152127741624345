// Required libraries (all shops)
import '@mod-wrd/js/auth'; //Login and authentication
import '@mod-publisher/js/richcontent/all'; //RTD support
import * as defaultshop from '@mod-webshop/js/composer/defaultshop'; //A shop
import Product from '@mod-webshop/js/shopservice/product';
import * as dialogapi from 'dompack/api/dialog';
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
import { GiftshopPromidataProduct } from './products/giftshopproduct';
import {Modal} from 'bootstrap/dist/js/bootstrap.js';

// HP Styling/design. This is removable
import 'bootstrap/dist/css/bootstrap.min.css';

import './shared/custom';
import './web/fonts/fontawesome.css';

import * as dialog from 'dompack/components/dialog';
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import * as cookie from "dompack/extra/cookie";

import './giftsdirect.scss';
import './rtd';
import './header';
import './pages';
import './footer';
import './widgets';
import './shared/forms';
import './shared/pagination';
import './components/select/';

import '@mod-forshops/shopextensions/cookiebar/frontend/cookiebar';

function refreshCartStatus(webshop)
{
  if (webshop.getCart().numarticles == 0)
    return;
  let cartnode = dompack.qS('#hp__numincart');
  if(cartnode)
  {
    dompack.qS('#hp__numincart').classList.add("badge","badge-pill","badge-primary");
    dompack.qS('#hp__numincart').textContent = webshop.getCart().numarticles;
  }
}

function onProductAdded(evt)
{
  if( !evt.detail.productnode )
    return;//Current page is not an productpage

  if(!evt.defaultPrevented)
  {
    evt.preventDefault();

    if(evt.target.closest(".giftsdirect-gotoquote"))
       setTimeout(() => evt.detail.webshop.gotoRequestQuote(), 100);
     else
       setTimeout(() => evt.detail.webshop.gotoCheckout(), 100); //TODO you may not need the timeout if you configure GTM to use beacons!  
   }
}
addEventListener('webshop:productadded', onProductAdded);

//the dialog implementation can differ, but -some- form of dialog callback needs to be registered using dialogapi.setupDialogs
dialogapi.setupDialogs(options => dialog.createDialog('mydialog', options));

function shopReady(webshop)
{
  dompack.register('.productform', node =>
  {
    new Product(webshop, node);
  });

  refreshCartStatus(webshop);
  dompack.dispatchCustomEvent(window, "giftsdirect:shopready", { bubbles:false, cancelable:false, detail: { webshop } });
  window.addEventListener("webshop:cartupdated", evt => refreshCartStatus(evt.detail.webshop));
}

function onNewCartDom(dom)
{
  dompack.qSA(dom, ".webshop-listcart__delete").forEach(node => node.appendChild(<span class="fa fa-trash-o" style="font-size:18px;"></span>));
}

//configures the shop and registers handlers
defaultshop.setup(
    { onReady: shopReady
    , productpagetypes: [ { name: 'forshops:promidataproduct', handler: GiftshopPromidataProduct } ]
    , onNewCartDom
    , listprices: false //disabling listprices cleans up logging due by reducing calculations
    });

openLinksInNewWindow( { extensions : ["pdf"] });

dompack.register('.hp-checkout__addtrigger', node => node.addEventListener('click', evt =>
{
  dompack.stop(evt);
  dompack.qSA('.hp-checkout__addcode').forEach(node => node.style.height = node.scrollHeight + 'px');
}));

function mobileProductFilterPlaceholder()
{
  //on mobile, move product filter block to placeholder
  if(!document.documentElement.classList.contains("has--filterconfig"))
    return;

  window.addEventListener("orientationchange", function() {
          location.reload(true);
  }, false);

  if(!window.matchMedia("(max-width:992px)").matches)
    return;

  let insertionpoint = dompack.qS('.mobileproductfilter_placeholder');
  if(!insertionpoint)
    return;

  let moveto = dompack.qS('.widget-productfilter');
  if(!moveto)
    return;
  
  insertionpoint.appendChild(moveto);

}
dompack.onDomReady(mobileProductFilterPlaceholder);

function fixupMobileOrderTop()
{
  //on mobile, move any item marked as mobile-order-top up
  if(!window.matchMedia("max-width:768px"))
    return;

  let insertionpoint = dompack.qS('.hp__mobileitemsplaceholder');
  if(!insertionpoint)
    return;

  let moveup = dompack.qSA('main > .mobile-order-top');
  if(moveup.length == 0)
    return;

  moveup.forEach(tomoveup =>
  {
    if(tomoveup.previousSibling && tomoveup.previousSibling.nodeName.match(/^H[123456]$/)) //allow the previous heading to move along with us!
      insertionpoint.parentNode.insertBefore(tomoveup.previousSibling, insertionpoint);
    insertionpoint.parentNode.insertBefore(tomoveup, insertionpoint);
  })

}

dompack.onDomReady(fixupMobileOrderTop);
