///x @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from '@webhare/dompack';
import * as dialogapi from 'dompack/api/dialog';
import { frontendConfig } from '@webhare/frontend';
import { PromidataProduct, PromidataProductInfo } from '@mod-forshops/shopextensions/promidata/frontend/promidataproduct';
import { Product } from "@mod-webshop/js/shopservice/product";

export class GiftshopPromidataProduct extends PromidataProduct {
  productinfo;

  constructor(node: HTMLElement, product: Product, initialhashparams: URLSearchParams) {
    super(node, product, initialhashparams, { minimumprice: 150 });
    this.productinfo = frontendConfig.obj.promidataproduct as PromidataProductInfo;

    for (const promidataquoterequest of this.node.querySelectorAll(".giftsdirect-gotoquote"))
      promidataquoterequest.addEventListener("click", evt => this._checkMinQuantityRequestQuote(evt));

    for (const promidataminquantity of this.node.querySelectorAll(".promidata--minquantity")) {
      promidataminquantity.innerHTML = String(this._checkMinQuantityAmount());
    }

    this._checkPriceRequestStatus();
  }

  _checkMinQuantityAmount() {
    const selectedoptions = this._getCurrentOptions();
    const selectedoptions_str = JSON.stringify(selectedoptions);
    const rawprices = this.productinfo.pricematrix.filter(row => JSON.stringify(row.options) === selectedoptions_str);
    const minquantity = rawprices[0].prices.filter(_ => _.quantity !== 1).map(_ => _.quantity)[0]; // filter out if min quantity is 1

    return minquantity || 1;
  }

  _checkMinQuantityRequestQuote(evt: Event) {
    const quoteconfig = this.checkValidQuoteConfiguration();
    if (!quoteconfig.valid) {
      let messagetext;
      if (quoteconfig.minimumpersize)
        messagetext = `Er kan geen offerte worden aangevraagd, omdat het minimale aantal niet wordt gehaald. Het minimale aantal is ${quoteconfig.requiredminimum} en ten minste ${quoteconfig.minimumpersize} per maat`;
      else
        messagetext = `Er kan geen offerte worden aangevraagd, omdat het minimale aantal niet wordt gehaald. Het minimale aantal is ${quoteconfig.requiredminimum}`;

      dompack.stop(evt);
      dialogapi.runMessageBox(
        <div>
          <p>{messagetext}</p>
        </div>, [{ title: "OK" }], { allowcancel: true });

      return;
    }
  }

  _checkQuantityAmount() {
    const totalquantity = this._getTotalQuantity();
    if (totalquantity === 0) {
      dompack.qR<HTMLButtonElement>('button.giftsdirect-gotoquote').disabled = true;
    } else {
      dompack.qR<HTMLButtonElement>('button.giftsdirect-gotoquote').disabled = false;
    }
  }

  _getTotalQuantity() {
    const res = this._describeCurrentConfiguration("0");
    return res?.totalquantity ?? 0;
  }

  _checkPriceRequestStatus() {
      const priceElement = dompack.qR<HTMLElement>('.webshop-product__price');
      const buttonElement = dompack.qR<HTMLButtonElement>('button.giftsdirect-gotoquote');
      const res = this._describeCurrentConfiguration("0");

      if (res && priceElement && buttonElement) {

          const finalPrice = typeof res.finalprice === 'string'
            ? parseFloat(res.finalprice)
            : res.finalprice ?? 0;

          if (res.anyonrequest === true && finalPrice >= 150) {
              buttonElement.classList.add('priceonrequest');
          } else {
              buttonElement.classList.remove('priceonrequest');
          }
      }
  }

  override calculatePrice(baseprice: string/*finmath.FinmathInput*/, hashparams: URLSearchParams, amount: number): string | "onrequest" {
    this._checkQuantityAmount();
    this._checkPriceTablesUpdate();
    this._checkPriceRequestStatus();
    const priceinfo = this._getPrice(baseprice);
    if (!priceinfo || priceinfo.anyonrequest || !priceinfo.finalprice)
      return "onrequest";

    return priceinfo?.finalprice;
  }
}
