import * as dompack from 'dompack';
import * as dialog from 'dompack/components/dialog';
import * as dialogapi from 'dompack/api/dialog';
import {Modal} from 'bootstrap/dist/js/bootstrap.js';
import * as whintegration from '@mod-system/js/wh/integration';
import PromidataProduct from '@mod-forshops/shopextensions/promidata/frontend/promidataproduct';
import * as finmath from '@mod-system/js/util/finmath';

export default class GiftshopPromidataProduct extends PromidataProduct
{
  constructor(node, product, initialhashparams)
  {
    super(node, product, initialhashparams, { minimumprice: 150 });
    this.productinfo = whintegration.config.obj.promidataproduct;

    for(let promidataquoterequest of this.node.querySelectorAll(".giftsdirect-gotoquote"))
      promidataquoterequest.addEventListener("click", evt => this._checkMinQuantityRequestQuote(evt));

    this.product.oncalculateprice = (baseprice, hashparams, amount) => this.calculatePrice(baseprice, hashparams, amount);
  }

  _checkMinQuantityRequestQuote()
  {
    let selectedoptions = this._getCurrentOptions();
    let selectedoptions_str = JSON.stringify(selectedoptions);
    let rawprices = this.productinfo.pricematrix.filter(row => JSON.stringify(row.options) == selectedoptions_str);
    
    let minquantity = rawprices[0].prices.filter(_ => _.quantity != "1").map(_ => _.quantity)[0]; // filter out if min quantity is 1
    let getquantity = this._getTotalQuantity();

    const quoteconfig = this.checkValidQuoteConfiguration();
    if(!quoteconfig.valid) {
      let messagetext;
      if(quoteconfig.minimumpersize)
        messagetext = `Er kan geen offerte worden aangevraagd, omdat het minimale aantal niet wordt gehaald. Het minimale aantal is ${quoteconfig.requiredminimum} en ten minste ${quoteconfig.minimumpersize} per maat`;
      else
        messagetext = `Er kan geen offerte worden aangevraagd, omdat het minimale aantal niet wordt gehaald. Het minimale aantal is ${quoteconfig.requiredminimum}`;

      dialogapi.runMessageBox(
          <div>
            <p>{messagetext}</p>
          </div>, [ { title:"OK" } ], { allowcancel:true });

      return;
    }
  }

  _getTotalQuantity()
  {
    let res = this._describeCurrentConfiguration();
    return res.totalquantity;
  }

  calculatePrice(baseprice, hashparams) {
    this._checkPriceTablesUpdate();
    let priceinfo = this._getPrice(baseprice);
    if(!priceinfo || priceinfo.anyonrequest || !priceinfo.finalprice)
      return "onrequest";

    return priceinfo?.finalprice;
  }

}
