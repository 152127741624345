import "./categorypage.scss";
import "./productpage.scss";
import * as dompack from 'dompack';
import * as domevents from "dompack/src/events";
import * as merge from 'dompack/extra/merge';
import * as finmath from '@mod-system/js/util/finmath';

import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import Lightbox from 'bs5-lightbox';

dompack.onDomReady(() =>
{
  document.querySelectorAll('.nav-item [data-bs-toggle="tab"]').forEach(item => {
    item.addEventListener('click', function (e) {
      e.preventDefault();
      document
        .querySelector(this.getAttribute('data-bs-target'))
        .scrollIntoView({ behavior: 'smooth' });
    });
  });

  // init magnific popup in productpage
  var form = document.getElementById("product__images__preview__container");
  var have_images = document.querySelectorAll('.product__images__grid, .product__images__preview').length !== 0;

  if (typeof (form) != 'undefined' && form !== null && have_images)
  {
    const Lightboxoptions = {
      keyboard: true,
      size: 'lg'
    };

    document.querySelectorAll('.bs5-lightbox').forEach((el) => el.addEventListener('click', (e) => {
      e.preventDefault();
      const lightbox = new Lightbox(el, Lightboxoptions);
      lightbox.show();
    }));


    let slidermain = new Glide('#product__images__preview__container', 
    {
      type: 'carousel',
      perView:1,
      keyboard: true,
    }).mount()

    let productimagesgrid = document.querySelector('.product__images__grid');
    if (productimagesgrid) 
    {
      const initialSlide = document.querySelector(".product__images__grid").dataset.initialidx | 0;

      let sliderthumbs = new Glide('.product__images__grid', 
      {
        type: 'carousel',
        perView:4,
        gap:10,
        keyboard: true,
        focusAt: 'center',
        breakpoints: {
            1400: {
              perView: 5
            },
            992: {
              perView: 4
            },
            768: {
              perView: 3
            },
            480: {
              perView: 2
            }
        }
      }).mount()

      Array.from(document.querySelectorAll('.slide-nav')).forEach(el => 
      {
        el.addEventListener('click', (e) => 
        {
          slidermain.go('='+(e.target.dataset.index))
          sliderthumbs.go('='+(e.target.dataset.index))
        })
      })

      Array.from(document.querySelectorAll('.slide-nav img')).forEach(el => 
      {
        el.addEventListener('click', (e) => 
        {
          slidermain.go('='+(e.target.dataset.index))
          sliderthumbs.go('='+(e.target.dataset.index))
        })
      })

      sliderthumbs.on('swipe.end', function() 
      {
        slidermain.go('='+(sliderthumbs.index))
      })

      sliderthumbs.on('move.after', function() 
      {
        slidermain.go('='+(sliderthumbs.index))
      })
      slidermain.on('swipe.end', function() 
      {
        sliderthumbs.go('='+(slidermain.index))
      })

      slidermain.on('move.after', function() 
      {
        sliderthumbs.go('='+(slidermain.index))
      })
      merge.registerUpdater("giftsdirectProductPagePreviewImage", (node, data) =>
      {
        node.dataset.initialidx = data.idx;
        slidermain.go('='+data.idx);
        sliderthumbs.go('='+data.idx);
      });
    }
  }
});

let target = dompack.qS('#btn-filter');

if (typeof (target) != 'undefined' && target !== null)
{
  target.onclick = function (e)
  {
    let elem = dompack.qS('#productsgrid_anchor');
    elem.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' });
    e.preventDefault();
  }
}

(function () {
  'use strict'

  var forms = document.querySelectorAll('.modalform form')

  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
})()

// Verwerkt imprint informatie
addEventListener("forshops:promidata-priceinfo", event =>
{
  let inject_into_element = dompack.qS("#promidata-price-info"); //TODO update deze selector

  console.log("priceupdate", inject_into_element, event.detail);

  let { rawprices, rawimprints, baseprices, imprints, quantities, sizes } = event.detail;

  let minimum_order_quantity = dompack.qSA(".minquantity"); // get first quantity amount
  let div_array = [...minimum_order_quantity];
  div_array.forEach(div => 
  {
    div.innerHTML = Object.values(quantities)[0]
  });

  if(!rawprices[0]?.prices.length)
  {
    inject_into_element.replaceChildren();
    return;
  }

  inject_into_element.replaceChildren(
    <div class="card mt-3">
      <div class="card-body">
        <h2>Bent u ook op zoek naar de laagste prijs?</h2>
        <p>Vraag geheel vrijblijvend de laagste prijs aan. Wij maken razendsnel een scherpe prijs voor u. Onderstaande prijzen zijn indicaties voor dit artikel zonder opdruk.</p>
       <div id="priceinfo-noprint">
         <table class="table table-striped table-sm">
           <thead>
             <tr>
               { sizes.length ? <th class="d-none" scope="column">Size</th> : null}
               { quantities.map( quantity => <th scope="column">{quantity} st.</th> )}
             </tr>
           </thead>
           <tbody>
             { baseprices.map(row =>
               <tr>
                 { sizes.length ? <th class="d-none" scope="row">{row.size}</th> : null }
                 { row.perpiece.map( perpiece =>
                     <td> {finmath.formatPrice(perpiece,'.',2)}</td>
                   )}
               </tr>)
             }
           </tbody>
         </table>

         { imprints.map(position =>
             <div class="d-none">
               <p>- {position.title}</p>
               <table>
                 <thead>
                   <tr>
                     <th></th>
                     { quantities.map( quantity => <th scope="column">{quantity} st.</th> )}
                   </tr>
                 </thead>
                 { position.options.map(row =>
                   <tr>
                     <th scope="row">{row.title}</th>
                     { row.perpiece.map( perpiece =>
                         <td>{finmath.formatPrice(perpiece,'.',2)} st.</td>
                       )}
                   </tr>)
                 }
               </table>
               <hr/>
             </div>
             )
         }
       </div>
       <div class="alert alert-info">
        Wij maken gratis een vrijblijvende visual met uw logo. Logo uploaden in eps, ai of pdf vector bestand. 
        <a href="#" class="giftsdirect-gotoquote">Klik hier!</a> 
       </div>
      </div>
    </div>
   );
});
